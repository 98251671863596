#tab-panel{
    max-height :max-content !important ;
    overflow-y :hidden !important;
}
#emoji-palette {
    position: static !important;
    max-width: max-content !important ;
    max-height: max-content !important ;
}
.invalid-react-quill {
    border: 1px solid #f86c6b !important;
    border-radius: 0.25rem !important;
    min-height: 500px;
}

.quill {
    border: 1px solid #e4e7ea !important;
    border-radius: 0.25rem !important;
    min-height: 500px;
}

.ql-toolbar,
.ql-container {
    border: none !important;
}

.loading-container {
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #fdfdfd;
}

.spinner-border {
    width: 4rem;
    height: 4rem;
}
/* .table-checkbox{
    height: 30px;
    width: 30px;
    transform: translate(60% , 10%);
} */

input[type="checkbox"]{
    height: 20px;
    width: 20px;
}
thead tr th:nth-child(1) input[type="checkbox"]{
    position: absolute;
    margin-top: -10px;
    margin-left: -10px;
    height: 20px;
    width: 20px;
}
tbody tr td:nth-child(1) input[type="checkbox"]{
    position: absolute;
    top: 50%;
    left: 100%;
    bottom: 10%;
    margin-left: -10px;
    height: 20px;
    width: 20px;
}