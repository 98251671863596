.languageSwitch .switch-slider {
  background-color: #20a8d8 !important;
  border-color: #1985ac !important;
}

.languageSwitch .switch-slider::before {
  border-color: #39b2d5 !important;
}

.languageSwitch .switch-slider::after {
  color: #fff !important;
}

.filter-tabs .nav-link.active {
  background: #20a8d8 !important;
  color: #fff !important;
}

.inputOpen {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid #e4e7ea;
}

.suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.suggestionHighlighted {
  background-color: lightgrey;
}

.isRTL .stepper-container .stepper-item .stepper-title {
  transform: translate(39%, 5px) !important;
}

.isLTR .stepper-container .stepper-item .stepper-title {
  transform: translate(-39%, 5px) !important;
}

.pagination {
  margin-bottom: 0rem;
}

*[dir="rtl"] .modal-header .close {
  margin: -1rem auto -1rem -1rem;
}

.ql-editor {
  text-align: left;
}

*[dir="rtl"] .ql-editor {
  text-align: right;
}

.en-template .ql-editor {
  text-align: left !important;
}

.ar-template .ql-editor {
  text-align: right !important;
}

*[dir="rtl"] .ql-snow .ql-picker-label {
  padding-right: 20px;
}

.modal-footer > *,
.card-footer > * {
  margin: 0.25rem;
}

.card-footer {
  padding: 0.75rem 1rem !important;
}

.modal-footer {
  padding: 0.75rem !important;
}

.lang-label {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.language-dropdown .dropdown .nav-link {
  text-transform: uppercase;
}
#paginationDiv::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#paginationDiv::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

#paginationDiv::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ff5e09;
  position: absolute;
}

.participants {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.participant {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0px;
}

.participant .inputGroup {
  width: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.inputGroup label {
  margin-top: 7px;
  margin-left: 10px;
  word-wrap: normal;
}

.participant .inputGroup .checkInput {
  height: 20px;
  width: 20px;
}

.Collapsible {
  /* background-color: indianred; */
}
.Collapsible__trigger {
  background-color: indigo;
}
.Collapsible__contentOuter {
  background-color: indigo;
}

.additionalClassForHead {
  background-color: #ff5e09 !important;
}

.dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ff5e09;
  position: absolute;
}

#post_desc::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#post_desc::-webkit-scrollbar {
  width: 1px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

#post_desc::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ff5e09;
  position: absolute;
}
#post_card.card-header {
  border: none;
}
.infinite-scroll-component {
  overflow-x: hidden !important;
}
.participant-modal {
  width: 800px !important;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.list-group-item {
  border: none !important;
  padding-bottom: 0;
  margin-bottom: -10px !important;
}

.courseDate .react-datepicker-wrapper {
  width: 100%;
}
.courseDate .react-datepicker-wrapper div input {
  /* background-color: #ff5e09; */
  /* width: 48%; */
  width: 100%;
  height: 37px;
  border: 1px solid #00000030;
  border-radius: 3px;
  padding: 10px;
}

.search-options {
  flex-direction: row;
}
.card-header-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.quill {
  min-height: 240px !important;
  max-height: 240px !important;
  overflow-y: scroll;
}

.invalid-input {
  border: 1px solid #f86c6b !important;
}

.validation-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f86c6b;
}

@media screen and (max-width: 1359px) {
  .courseDate .react-datepicker-wrapper div input {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .courseDate .react-datepicker-wrapper div input {
    /* width: 155%; */
  }
}

.fc {
  /* the calendar root */
  max-width: 1250px;
  margin: 0 auto;
}

.fc-list-event,
.fc-day {
  cursor: pointer;
}

.filter_header .form-group {
  margin-bottom: 0px;
  margin-left: 10px;
}

.fc-list-event .fc-daygrid-event-dot {
  display: none;
}

.fc-daygrid-event-dot,
.fc-list-event-dot {
  border-color: #ff5e09 !important;
}

@media only screen and (max-width: 800px) {
  .search-options {
    flex-direction: column;
  }

  .card-header-div {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
  }

  .search-inputs {
    width: 200px;
    margin-bottom: 15px;
  }

  .search-inputs > .form-group {
    margin-bottom: 0;
  }

  .filter-dropdown {
    margin-bottom: 20px !important;
  }

  .table-title {
    margin-bottom: 15px;
  }
}
